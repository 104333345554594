import React from "react";
import "./css/dealerdata_panel.css";
import ValidationInputField from "../utils/components/ValidationInputField";
import ImageUploadPanel from "../utils/components/ImageUploadPanel";
import useScbFormField from "../utils/form/ScbFormFieldHook";

const EditDealerDataPanel = ({ nrOfOffer }) => {
  const {
    [`dealerName${nrOfOffer ? nrOfOffer : ""}`]: dealerName,
    [`dealerStreetAndNr${nrOfOffer ? nrOfOffer : ""}`]: dealerStreetAndNr,
    [`dealerZipCode${nrOfOffer ? nrOfOffer : ""}`]: dealerZipCode,
    [`dealerCity${nrOfOffer ? nrOfOffer : ""}`]: dealerCity,
    [`dealerEmail${nrOfOffer ? nrOfOffer : ""}`]: dealerEmail,
    [`dealerWebPage${nrOfOffer ? nrOfOffer : ""}`]: dealerWebPage,
    [`dealerTelephoneNr${nrOfOffer ? nrOfOffer : ""}`]: dealerTelephoneNr,
    [`dealerLogo${nrOfOffer ? nrOfOffer : ""}`]: dealerLogo,
    [`dealerLogoPath${nrOfOffer ? nrOfOffer : ""}`]: dealerLogoPath,
    [`setDealerName${nrOfOffer ? nrOfOffer : ""}`]: setDealerName,
    [`setDealerStreetAndNr${nrOfOffer ? nrOfOffer : ""}`]: setDealerStreetAndNr,
    [`setDealerZipCode${nrOfOffer ? nrOfOffer : ""}`]: setDealerZipCode,
    [`setDealerCity${nrOfOffer ? nrOfOffer : ""}`]: setDealerCity,
    [`setDealerEmail${nrOfOffer ? nrOfOffer : ""}`]: setDealerEmail,
    [`setDealerWebPage${nrOfOffer ? nrOfOffer : ""}`]: setDealerWebPage,
    [`setDealerTelephoneNr${nrOfOffer ? nrOfOffer : ""}`]: setDealerTelephoneNr,
    [`setDealerLogo${nrOfOffer ? nrOfOffer : ""}`]: setDealerLogo,
    [`setDealerLogoPath${nrOfOffer ? nrOfOffer : ""}`]: setDealerLogoPath,
    readonly,
  } = useScbFormField(
    `dealerName${nrOfOffer ? nrOfOffer : ""}`,
    `dealerStreetAndNr${nrOfOffer ? nrOfOffer : ""}`,
    `dealerZipCode${nrOfOffer ? nrOfOffer : ""}`,
    `dealerCity${nrOfOffer ? nrOfOffer : ""}`,
    `dealerEmail${nrOfOffer ? nrOfOffer : ""}`,
    `dealerWebPage${nrOfOffer ? nrOfOffer : ""}`,
    `dealerTelephoneNr${nrOfOffer ? nrOfOffer : ""}`,
    `dealerLogo${nrOfOffer ? nrOfOffer : ""}`,
    `dealerLogoPath${nrOfOffer ? nrOfOffer : ""}`,
    "readonly"
  );

  return (
    <div className="row no-gutters">
      <div className="col-xl-6 col-lg-7 col-md-10">
        <div className="row no-gutters">
          <div className="col">
            <ValidationInputField
              name="name"
              label="Händlername"
              value={dealerName}
              onChange={setDealerName}
              readonly={readonly}
            />
          </div>
        </div>
        <div className="row no-gutters d-flex">
          <div className="col">
            <ValidationInputField
              name="streetAndNr"
              label="Adresse"
              value={dealerStreetAndNr}
              onChange={setDealerStreetAndNr}
              readonly={readonly}
            />
          </div>
        </div>
        <div className="row no-gutters d-flex">
          <div className="col-6 pr-3">
            <ValidationInputField
              name="zipCode"
              label="Postleitzahl"
              value={dealerZipCode}
              onChange={setDealerZipCode}
              readonly={readonly}
              maxLength={4}
            />
          </div>
          <div className="col-6 pl-3">
            <ValidationInputField
              name="city"
              label="Ort"
              value={dealerCity}
              onChange={setDealerCity}
              readonly={readonly}
            />
          </div>
        </div>
        <div className="row no-gutters d-flex">
          <div className="col-6 pr-3">
            <ValidationInputField
              name="email"
              label="E-Mail"
              value={dealerEmail}
              onChange={setDealerEmail}
              readonly={readonly}
              maxLength={60}
              email
            />
          </div>
          <div className="col-6 pl-3">
            <ValidationInputField
              name="telephoneNr"
              label="Telefonnummer"
              value={dealerTelephoneNr}
              onChange={setDealerTelephoneNr}
              readonly={readonly}
              phone
            />
          </div>
        </div>
        <div className="row no-gutters d-flex">
          <div className="col-6 pr-3">
            <ValidationInputField
              name="webPage"
              label="Webpage"
              value={dealerWebPage}
              onChange={setDealerWebPage}
              readonly={readonly}
              required={false}
              maxLength={60}
              web
            />
          </div>
        </div>
      </div>
      <div className="col pl-3">
        <ImageUploadPanel
          uploadButtonLabel="Logo hochladen"
          imagePath={dealerLogoPath}
          image={dealerLogo}
          imageChanged={(picture, picturePath) => {
            setDealerLogo(picture);
            setDealerLogoPath(picturePath);
          }}
          readonly={readonly}
        />
      </div>
    </div>
  );
};

export default EditDealerDataPanel;
